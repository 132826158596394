@import url(https://use.typekit.net/uwh0wmh.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 #root {
    height: 100vh;
}

.landing-wrapper {
    height: 100%;
    position: relative;
    z-index: 10;
    background: #F1F2B5;
    background: -webkit-gradient(linear, left top, left bottom, from(#F1F2B5), to(#135058));
    background: -webkit-linear-gradient(top, #F1F2B5, #135058);
    background: linear-gradient(to bottom, #F1F2B5, #135058);
    display: flex;
    justify-content: center;
    align-items: center;  
}

.landing-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(212, 217, 211, .1);
    z-index: 20;
}

.landing-card {
    width: 360px;
    border-radius: 3px;
    background: #fff;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .15);
    position: relative;
    z-index: 30;
}

.card-header {
    height: 240px;
    background: url(/static/media/san-sebastian.98ee9fae.jpg);
    background-size: cover;
    background-position: center center;
}

.card-profile {
    text-align: center;
    position: absolute;
    left: 0;
    top: 194px;
    height: 92px;
    width: 100%;
}

.card-profileImage {
    border: 4px solid #fff;
    display: inline-block;
    width: 84px;
    height: 84px;
    background: #f4f8fb;
    border-radius: 50%;
    overflow: hidden;
}

.card-profileImage img {
    width: 100%;
}

.card-text {
    padding: 56px 32px 32px 32px;
    font-family: brandon-grotesque,'Helvetica Neue',Helvetica,'Lucida Grande',sans-serif;
    -webkit-font-smoothing: antialiased;
}

.card-title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin: 2px 0 8px 0;
    color: #36393a;
}

.card-descr {
    font-size: 15px;
    text-align: justify;
    margin: 0;
    color: #87969d;
    margin-bottom: 8px;
}

.card-descr:last-child {
    margin: 0;
}

.card-text a {
    color: #36393a;
    text-decoration: none;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}
.card-text a:hover {
    color: #fa5555;
    text-decoration: underline;
}

.animated {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
            animation-name: fadeInDown;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
